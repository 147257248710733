<template>
    <section>
        <b-card>
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <div class="h3">
                    <i class="feather icon-grid text-primary mr-1" />Clienti
                </div>
            </div>

            <hr />

            <div>
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Cerca</label>
                            <b-form-input v-model="searchTerm" type="text" class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <vue-good-table :columns="columns" :rows="tableItems" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">
                        <!-- Column: Name -->
                        <div v-if="props.column.field === 'fullName'" class="text-nowrap">
                            <b-avatar :src="props.row.avatar" class="mx-1" />
                            <span class="text-nowrap">{{ props.row.fullName }}</span>
                        </div>

                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-end flex-wrap">

                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-card>
    </section>
</template>

<script>
import {
    BAvatar, BBadge, BCard, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table"
import { Requests } from '@/api/requests.js'

export default {
    components: {
        BAvatar,
        BBadge,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
    },

    data() {
        return {
            pageLength: 7,
            columns: [
                {
                    label: 'ID Progetto',
                    field: 'id_project',
                    type: 'number'
                },
                {
                    label: 'Cliente',
                    field: 'project_customer_companyname',

                },
                {
                    label: 'Città',
                    field: 'project_customer_city',
                },
                {
                    label: 'Indirizzo',
                    field: 'project_customer_address',

                },
                {
                    label: 'Data',
                    field: 'creation_date',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                    dateOutputFormat: 'dd/MM/yyyy'
                },
            ],
            tableItems: [],
            searchTerm: '',
        }
    },

    created() {
        this.getAllCustomers()
    },

    methods: {
        async getAllCustomers() {
            try {
                const response = await Requests.getAllCustomers();
                this.tableItems = response.data
            } catch (err) {
                console.log(err)
            }
        }
    }

}
</script>
